import React from "react"

import styled from "styled-components"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import { FaLinkedin } from "react-icons/fa"
// import { TextContainer } from "../components/textContainer"

const Container = styled.div`
  text-align: center;
  margin-top: 2rem;
  transform: translateX(-1rem);

  @media screen and (min-width: 576px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    width: 33.333%;
  }

  @media screen and (min-width: 1200px) {
    width: 25%;
  }
`

const PhotoContainer = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
  margin: 1rem auto;
`

const Photo = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  transform: ${props =>
    props.active
      ? "translate3d(-0.5rem, -0.5rem, 0)"
      : "translate3d(-1rem, -1rem, 0)"};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
`

const PhotoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  background: linear-gradient(
    135deg,
    ${props => props.theme.colorPrimary} 15%,
    ${props => props.theme.colorSecondary} 85%
  );
  border-radius: 50%;
  transform: ${props =>
    props.active
      ? "translate3d(0.5rem, 0.5rem, 0)"
      : "translate3d(1rem, 1rem, 0)"};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
`

const Name = styled.h4`
  margin: 0;
  font-size: 1rem;
`

const NameContainer = styled.div`
  display: inline-block;
  position: relative;
  //transform: translateX(-1rem);

  //justify-content: center;
  //align-items: center;
`

const LinkedInButton = styled.a`
  position: absolute;
  display: block;
  padding: 1rem;
  //margin-left: 0.25rem;
  right: -3rem;
  top: -1rem;
`

const LinkedInIcon = styled(FaLinkedin)`
  display: block;
`

const Position = styled.p`
  margin: 0;
  font-size: 0.875rem;
  //transform: translateX(-1rem);
`

const TeamMember = ({
  teamMember: {
    teamMemberImage,
    teamMemberName,
    teamMemberPosition,
    teamMemberLinkedinUrl,
  },
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  return (
    <Container ref={ref}>
      <PhotoContainer>
        <PhotoBackground active={inView} />
        <Photo
          fluid={teamMemberImage.localFile.childImageSharp.fluid}
          active={inView}
          alt={teamMemberImage.description}
        />
      </PhotoContainer>
      <NameContainer>
        <Name>{teamMemberName}</Name>
        {teamMemberLinkedinUrl && (
          <LinkedInButton
            target="_blank"
            rel="noreferrer"
            href={teamMemberLinkedinUrl}
          >
            <LinkedInIcon size={"1.25rem"} />
          </LinkedInButton>
        )}
      </NameContainer>
      <Position>{teamMemberPosition}</Position>
    </Container>
  )
}

TeamMember.defaultProps = {}

export default TeamMember
