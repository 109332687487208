import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import HeaderLogo from "../components/headerLogo"
import { WidthContainer } from "../components/widthContainer"
import Team from "../about/team"
import About from "../about/about"
import OurCores from "../about/ourCores"
import { Helmet } from "react-helmet"

const AboutPage = ({
  data: { site, contentfulHeader, contentfulFooter, contentfulAboutPage },
  uri,
}) => {
  const { siteName, logo } = contentfulHeader
  const { siteMetadata } = site

  const {
    pageShortTitle,
    pageTitle,
    pageDescription: { pageDescription },
    pageImage,
    heroText,
    teamTitle,
    teamSubtitle,
    teamText,
    teamMembers,
    joinUsTitle,
    joinUsText,
    aboutSubtitle,
    aboutTitle,
    ourCores,
  } = contentfulAboutPage

  return (
    <Layout
      footer={contentfulFooter}
      header={contentfulHeader}
      pageMeta={{ pageTitle, pageDescription, pageImage, uri }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: siteName,
                item: `${siteMetadata.siteUrl}`,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: pageShortTitle,
              },
            ],
          })}
        </script>
      </Helmet>
      <WidthContainer>
        <HeaderLogo logo={logo} hideMd />
      </WidthContainer>
      <Hero text={heroText} />
      <Team
        title={teamTitle}
        subtitle={teamSubtitle}
        text={teamText}
        nembers={teamMembers}
        joinUsTitle={joinUsTitle}
        joinUsText={joinUsText}
      />
      <About title={aboutTitle} subtitle={aboutSubtitle} />
      <OurCores ourCores={ourCores} />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulHeader {
      ...HeaderInformation
    }
    contentfulFooter {
      ...FooterInformation
    }
    contentfulAboutPage {
      pageShortTitle
      pageTitle
      pageDescription {
        pageDescription
      }
      pageImage {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              width
              height
              src
            }
          }
        }
      }
      heroText {
        json
      }
      teamTitle
      teamSubtitle
      teamText {
        json
      }
      teamMembers {
        teamMemberPosition
        teamMemberName
        teamMemberLinkedinUrl
        teamMemberImage {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 160, maxHeight: 160) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      joinUsTitle
      joinUsText {
        json
      }
      aboutSubtitle
      aboutTitle {
        aboutTitle
      }
      ourCores {
        coreTitle
        coreText {
          json
        }
        coreImage {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
