import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import { TextContainer } from "../components/textContainer"
import { useInView } from "react-intersection-observer"

const ImageContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 45%;
    margin-right: 1rem;
    margin-top: 2rem;
  }
`

const CoreContentContainer = styled(WidthContainer)`
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    width: 55%;
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
`

const CoreContentInnerContainer = styled(TextContainer)`
  @media screen and (min-width: 768px) {
    padding: 0;
  }
`

const CoreContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 704px;
    display: flex;
    margin: 6rem auto 0;

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      ${ImageContainer} {
        margin-right: 0;
        margin-left: 0.5rem;
      }

      ${CoreContentContainer} {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (min-width: 992px) {
    width: 928px;
  }

  @media screen and (min-width: 1200px) {
    width: 1104px;
  }

  @media screen and (min-width: 1400px) {
    width: 1304px;
  }
`

const Image = styled(Img)``

const CoreTitle = styled.h2`
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  color: ${props => props.theme.textColorEmphasis};
  position: relative;
  font-size: 1.75rem;
`

const CoreTitleBackgroundContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: -0.25rem;
  right: -0.5rem;
  z-index: -1;
`

const CoreTitleBackground = styled.div`
  background: ${props => props.theme.colorSecondary};
  width: ${props => (props.active ? "100%" : "0")};
  height: 1rem;
  transition: 250ms width ease-in-out;
`

const CoreText = styled.div`
  margin-top: 0.5rem;

  p {
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`

const OurCore = ({
  data: {
    coreTitle,
    coreText: { json },
    coreImage,
  },
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  return (
    <CoreContainer>
      <ImageContainer>
        <Image
          fluid={coreImage.localFile.childImageSharp.fluid}
          alt={coreImage.description}
        />
      </ImageContainer>
      <CoreContentContainer ref={ref}>
        <CoreContentInnerContainer>
          <CoreTitle>
            <CoreTitleBackgroundContainer>
              <CoreTitleBackground active={inView} />
            </CoreTitleBackgroundContainer>
            {coreTitle}
          </CoreTitle>
          <CoreText>{documentToReactComponents(json)}</CoreText>
        </CoreContentInnerContainer>
      </CoreContentContainer>
    </CoreContainer>
  )
}

export default OurCore
