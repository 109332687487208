import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import { WidthContainer } from "../components/widthContainer"
import TeamMember from "./teamMember"
import TeamJoinUs from "./teamJoinUs"
import { useInView } from "react-intersection-observer"

const Container = styled.section`
  overflow: hidden;
  margin-top: 2rem;
`

const Title = styled.h1`
  font-size: 1.75rem;
  color: ${props => props.theme.textColorEmphasis};
  text-transform: uppercase;
  line-height: 1.4;
  position: relative;
  margin: 0 0 1.5rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 4rem;
    background: ${props => props.theme.textColorEmphasis};
    height: 3px;
    bottom: -0.5rem;
  }
`

const Subtitle = styled.h2`
  margin: 0;
  font-weight: 500;
`

const Content = styled.div`
  margin-top: 1rem;

  p {
    margin: 1rem 0 0;
    &:first-child {
      margin-top: 0;
    }
  }

  b {
    color: ${props => props.theme.textColorEmphasis};
    background: linear-gradient(
        0deg,
        ${props => props.theme.colorSecondary} 0%,
        ${props => props.theme.colorSecondary} 60%,
        transparent 60%,
        transparent 60%
      )
      no-repeat bottom left;

    background-size: ${props => (props.active ? "100% 100%" : "0% 100%")};
    transition: background-size 1000ms ease-in-out;
  }
`

const InnerContainer = styled.div`
  margin-left: 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
    margin-right: 1rem;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }
`

const TitleContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 45%;
    padding-right: 1rem;
  }
`
const ContentContainer = styled.div`
  @media screen and (min-width: 768px) {
    padding-left: 1rem;
    width: 55%;
  }
`

const Members = styled.div`
  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 768px) {
    margin-top: 2rem;
  }
`

const Team = ({
  title,
  subtitle,
  text: { json },
  nembers,
  joinUsTitle,
  joinUsText,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  return (
    <Container>
      <WidthContainer>
        <InnerContainer>
          <TitleContainer>
            <Subtitle>{subtitle}</Subtitle>
            <Title>{title}</Title>
          </TitleContainer>
          <ContentContainer>
            <Content ref={ref} active={inView}>
              {documentToReactComponents(json)}
            </Content>
          </ContentContainer>
        </InnerContainer>
        <Members>
          {nembers.map((teamMember, index) => (
            <TeamMember key={index} teamMember={teamMember} />
          ))}
          <TeamJoinUs joinUsTitle={joinUsTitle} joinUsText={joinUsText} />
        </Members>
      </WidthContainer>
    </Container>
  )
}

Team.defaultProps = {
  nembers: [],
}

export default Team
