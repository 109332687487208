import React from "react"

import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Container = styled.div`
  text-align: center;
  margin-top: 2rem;

  @media screen and (min-width: 576px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) {
    width: 33.333%;
  }

  @media screen and (min-width: 1200px) {
    width: 25%;
  }
`

const InnerContainer = styled.div`
  max-width: 327px;
  margin: 0 auto;
`

const PhotoContainer = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
  margin: 1rem auto;
  transform: translateX(-1rem);
`

const EmptyPhoto = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 2px dashed #000000;
  transform: ${props =>
    props.active
      ? "translate3d(-0.5rem, -0.5rem, 0)"
      : "translate3d(-1rem, -1rem, 0)"};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
`

const PhotoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  height: 10rem;
  background: linear-gradient(
    135deg,
    ${props => props.theme.colorPrimary} 15%,
    ${props => props.theme.colorSecondary} 85%
  );
  border-radius: 50%;
  transform: ${props =>
    props.active
      ? "translate3d(0.5rem, 0.5rem, 0)"
      : "translate3d(1rem, 1rem, 0)"};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
`

const Name = styled.h4`
  margin: 0;
  font-size: 1rem;
`

const Position = styled.div`
  font-size: 0.875rem;

  p {
    margin: 0;
  }
`

const TeamJoinUs = ({ joinUsTitle, joinUsText: { json } }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  return (
    <Container ref={ref}>
      <InnerContainer>
        <PhotoContainer>
          <PhotoBackground active={inView} />
          <EmptyPhoto active={inView} />
        </PhotoContainer>
        <Name>{joinUsTitle}</Name>
        <Position>{documentToReactComponents(json)}</Position>
      </InnerContainer>
    </Container>
  )
}

TeamJoinUs.defaultProps = {}

export default TeamJoinUs
