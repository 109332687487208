import React from "react"
import styled from "styled-components"
import OurCore from "./ourCore"

const Container = styled.section`
  overflow: hidden;
  margin-top: 3rem;

  @media screen and (min-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
`

const OurCores = ({ ourCores }) => {
  return (
    <Container>
      {ourCores.map((ourCore, index) => (
        <OurCore key={index} data={ourCore} />
      ))}
    </Container>
  )
}

OurCores.defaultProps = {
  ourCores: [],
}

export default OurCores
