import styled from "styled-components"

export const TextContainer = styled.div`
  max-width: 343px;
  padding-left: 1rem;
  margin: 0 0 0 auto;

  @media screen and (min-width: 576px) {
    max-width: 512px;
    padding-left: 2rem;
    padding-right: 1rem;
  }
`
