import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import { WidthContainer } from "./widthContainer"
// import { TextContainer } from "./textContainer"
import { useInView } from "react-intersection-observer"

const Container = styled.section`
  overflow: hidden;
`

const Content = styled.h2`
  font-size: 1.5rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 2;
  margin-left: 1rem;

  @media (min-width: 576px) {
    margin-left: 2rem;
    margin-right: 1rem;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
    margin-top: 10rem;
    margin-bottom: 10rem;
    padding-right: 5rem;
  }

  p {
    margin: 1rem 0 0;
    font-style: italic;

    &:first-child {
      margin-top: 0;
    }
  }

  b {
    font-weight: 500;
    color: ${props => props.theme.textColorEmphasis};
    background: linear-gradient(
        0deg,
        ${props => props.theme.colorSecondary} 0%,
        ${props => props.theme.colorSecondary} 60%,
        transparent 60%,
        transparent 60%
      )
      no-repeat bottom left;

    background-size: ${props => (props.active ? "100% 100%" : "0% 100%")};
    transition: background-size 1000ms ease-in-out;
  }
`

const Hero = ({ text: { json } }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <WidthContainer>
        <Content active={inView}>{documentToReactComponents(json)}</Content>
      </WidthContainer>
    </Container>
  )
}

export default Hero
