import React, { useState } from "react"
import { WidthContainer } from "../components/widthContainer"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
// import SEO from "../components/seo"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Container = styled.section`
  overflow: hidden;
  margin-top: 3rem;
  position: relative;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    margin-top: 5rem;
    padding-bottom: 2rem;
  }
`

const TitleContainer = styled(WidthContainer)``

const Title = styled.h2`
  text-align: center;
  margin: 2rem;
  position: relative;
  text-transform: uppercase;
  color: ${props => props.theme.textColorEmphasis};
  font-weight: 500;
  font-style: italic;
  line-height: 2;

  &:before {
    content: "“";
    position: absolute;
    top: -1rem;
    left: -2rem;
    font-size: 4rem;
    line-height: 1;
    color: ${props => props.theme.colorPrimary};
  }

  &:after {
    content: "”";
    position: absolute;
    right: -2rem;
    bottom: -3rem;
    font-size: 4rem;
    line-height: 1;
    color: ${props => props.theme.colorPrimary};
  }

  @media (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 1.75;
  }
`

const SubtitleContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
`

const Subtitle = styled.h3`
  margin: 0;
  font-size: 7rem;
  white-space: nowrap;
  line-height: 1;
  color: white;
  -moz-text-fill-color: #fff;
  -webkit-text-fill-color: #fff;
  -moz-text-stroke-color: ${props => props.theme.colorPrimary};
  -webkit-text-stroke-color: ${props => props.theme.colorPrimary};
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-width: 1px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 6rem;
`

const About = ({ title: { aboutTitle }, subtitle }) => {
  const [displacement, setDisplacement] = useState(0)

  useScrollPosition(({ currPos: { y } }) => {
    setDisplacement(
      ((-y / window.document.documentElement.clientHeight) * 2) % 1
    )
  })

  return (
    <Container>
      <SubtitleContainer>
        <Subtitle
          style={{
            transform: `translate3d(-${displacement * 100}%, 0, 0)`,
          }}
        >
          {subtitle}
        </Subtitle>
        <Subtitle
          style={{
            transform: `translate3d(-${displacement * 100}%, 0, 0)`,
          }}
        >
          {subtitle}
        </Subtitle>
      </SubtitleContainer>
      <TitleContainer>
        <Title>{aboutTitle}</Title>
      </TitleContainer>
    </Container>
  )
}

export default About
